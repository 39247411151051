import { GridFilterItem, GridFilterModel, GridSortModel } from '@mui/x-data-grid';

export function getOrderbyQueryParam(
  sortModel: GridSortModel | undefined,
  excludeAND?: boolean,
  excludeOrderby?: boolean
) {
  return sortModel?.length
    ? `${excludeOrderby ? '' : `${excludeAND ? '' : '&'}$orderby=`}${sortModel
        .map((sortItem) => `${sortItem.field} ${sortItem.sort}`)
        .join(',')}`
    : '';
}

export function getFilterQueryParam(
  filterModel: GridFilterModel | undefined,
  excludeFilterPrefix?: boolean,
  excludeFilterBy?: boolean
) {
  if (!filterModel?.items?.length) return '';

  const filterItems: string[] = [];

  filterModel?.items?.forEach((filterItem: GridFilterItem) => {
    const operatorValue = filterItem.operatorValue;
    const columnField = filterItem.columnField;
    let value = filterItem.value;

    if (typeof value === 'string') {
      value = value.replace(/'/g, "''");
    }

    let filter = '';
    switch (operatorValue) {
      case 'contains':
      case 'startsWith':
      case 'endsWith':
        if (!value) {
          filter = '';
          break;
        }
        filter = `${operatorValue}(${columnField}, '${value}')`;
        break;
      case 'equals':
        if (!value) {
          filter = '';
          break;
        }
        filter = `${columnField} eq '${value}'`;
        break;
      case '=':
      case 'is':
        if (!value) {
          filter = '';
          break;
        }
        filter = `${columnField} eq ${value}`;
        break;
      case 'not':
        filter = `${columnField} ne ${value}`;
        break;
      case '<':
      case 'before':
        if (!value) {
          filter = '';
          break;
        }
        filter = `${columnField} lt ${value}`;
        break;
      case '>':
      case 'after':
        if (!value) {
          filter = '';
          break;
        }
        filter = `${columnField} gt ${value}`;
        break;
      case '>=':
      case 'onOrAfter':
        if (!value) {
          filter = '';
          break;
        }
        filter = `${columnField} ge ${value}`;
        break;
      case '<=':
      case 'onOrBefore':
        if (!value) {
          filter = '';
          break;
        }
        filter = `${columnField} le ${value}`;
        break;
      case 'isEmpty':
        filter = `${columnField} eq null`;
        break;

      case 'isNotEmpty':
        filter = `${columnField} ne null`;
        break;
    }

    if (filter) {
      filterItems.push(filter);
    }
  });

  const filterString = filterItems?.join(` ${filterModel.linkOperator ?? 'and'} `);
  if (!filterString) {
    return '';
  }
  if (excludeFilterPrefix) {
    return filterString;
  }

  return `${excludeFilterBy ? '' : `&$filter=`}${filterString}`;
}

export function getSkipQueryParam(skip: number | undefined) {
  return skip !== undefined ? `&$skip=${skip}` : '';
}

export function getTopQueryParam(top: number | undefined) {
  return top !== undefined ? `&$top=${top}` : '';
}

type CombineFiltersParams = {
  filters: (string | undefined)[];
  operator: 'and' | 'or';
};
export const combineFilters = ({ filters, operator }: CombineFiltersParams) => {
  // Filter out undefined filters
  const validFilters = filters
    .filter((filter) => filter && Boolean(filter?.length))
    ?.map((filter) => filter?.replace(/^&\$filter=/, '').trim()); // Remove '&$filter=' and trim any whitespace;

  // Combine filters using the specified logical operator
  let combinedFilters = validFilters.join(` ${operator} `);

  combinedFilters = encodeURIComponent(combinedFilters);

  return `&$filter=(${combinedFilters})`;
};
