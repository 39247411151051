import { ITranslator } from './translationInterface';
export const translator = (t: (key: string, params?: any) => string): ITranslator => {
return {
orders: () => {return `Ordre`},
orderDetails: () => {return `Ordre detaljer`},
surveyOrders: () => {return `Survey Orders`},
productOrders: () => {return `Product Orders`},
billableHoursOrders: () => {return `Billable Hours Orders`},
totalSmsUsage: () => {return `Sammenlagt sms forburg`},
totalEmailUsage: () => {return `Sammenlagt email forbrug`},
changeLanguage: () => {return `Skift sprog`},
addItem: (item: string) => {return `Opret ${item}`},
orgHasNoLicense: () => {return `Denne organisation har ingen licens. En licens er påkrævet for at oprette ordre.`},
createLicenseHelpText: () => {return `Klik her for at begynde på at oprette en licens.`},
overUsage: (usage: string) => {return `Over forbrug: ${usage}`},
email: () => {return `Email`},
sms: () => {return `Sms`},
saveItem: (item: string) => {return `Gem ${item}`},
surveyDetails: () => {return `Survey detaljer`},
useSurveyQuota: () => {return `Brug Survey Kvote`},
quotasCannotBeZero: () => {return `Mindst én kvote skal være større end 0.`},
emailQuota: () => {return `Email Kvote`},
smsQuota: () => {return `Sms Kvote`},
surveyQuota: () => {return `Survey Kvote`},
description: () => {return `Description`},
notes: () => {return `Note`},
active: () => {return `Aktiv`},
inactive: () => {return `Inaktiv`},
renewable: () => {return `Kan fornyes`},
notRenewable: () => {return `Kan ikke fornyes`},
startDate: () => {return `Start Dato`},
usagePeriod: () => {return `Forbrugsperiode`},
usagePeriodInMonths: () => {return `Forbrugsperiode i måneder`},
usagePeriodInMonthsDescription: (renewable: string) => t("usagePeriodInMonthsDescription", {renewable: renewable}),
billingPeriod: () => {return `Faktureringsperiode`},
billingPeriodInMonths: () => {return `Faktureringsperiode i måneder`},
billingPeriodInMonthsDescription: () => {return `Det antal måneder der skal gå fra startdatoen før ordren faktureres`},
monthText: (months: string) => t("monthText", {months: months}),
orderEndDateTitle: (renewable: string) => t("orderEndDateTitle", {renewable: renewable}),
orderEndDateDescription: (renewable: string) => t("orderEndDateDescription", {renewable: renewable}),
noEndDate: () => {return `Ingen udløbsdato`},
expiredOn: (date: string) => {return `Udløb d. ${date}`},
invalidDate: (date: string, error: string) => {return `Ugyldig dato: ${date}. Fejl: ${error}`},
billingDate: () => {return `Faktureringsdato`},
billed: () => {return `Faktureret`},
dateDifference: (type: string, diff: string) => t("dateDifference", {type: type, diff: diff}),
integerError: (field: string) => {return `${field} skal være en integer`},
fieldRequiredError: (field: string) => {return `${field} er påkrævet`},
minimumValueError: (field: string, min: string) => {return `${field} skal være større end ${min}`},
maximumValueError: (field: string, max: string) => {return `${field} må ikke være større end ${max}`},
amount: () => {return `Antal`},
oneOffOrderHelperText: () => {return `One Off Orders bliver oprettet uden forbrugs- og faktureringsperioder. De vil ikke have en en udløbsdato.`},
respondentAsRecipientQuestion: () => {return `Sæt respondent som NPS notifikation modtager?`},
respondentAsRecipientWarning: () => {return `Advarsel: Hver respondent vil modtage en NPS notifikation e-mail umiddelbart efter at have fuldført surveyet, forudsat at NPS-kriterierne er opfyldt.`},
npsCriteria: () => {return `NPS Kriteria`},
notificationChannel: () => {return `Notifikationskanal`},
npsNotification: () => {return `NPS Notifikation`},
recipientConfiguration: () => {return `Modtagerkonfiguration`},
webhookCall: () => {return `Webhook kald`},
webhookUrl: () => {return `Webhook URL`},
singleEmail: () => {return `Enkelt e-mail`},
emailToSender: () => {return `E-mail til afsender`},
emailToRespondentOwner: () => {return `E-mail til respondentens ejer`},
emailToCampaignResponsible: () => {return `E-mail til ansvarlig for modtager`},
emailToRespondent: () => {return `E-mail til respondent`},
emailAddress: () => {return `E-mail adresse`},
deleteNotificationTriggerTitle: () => {return `Slet NPS Notifikation trigger?`},
deleteNotificationTriggerDescription: () => {return `Er du sikker på at du vil slette NPS Notifikation triggeren?`},
test: () => {return `Test`},
notificationTriggers: () => {return `NPS Notifikation triggers`},
notificationTriggersDescription: () => {return `Opsæt NPS notifikationer for at blive underrettet, når en respondent giver en bestemt reaktion på en kampagne. Dette gør det muligt for dig hurtigt at handle på kundefeedback.`},
notificationTriggerSenderDescription: () => {return `For e-mail notifikationer bestemmes afsenderadressen af modtagertypen. E-mails sendt til en <i>Respondent</i> bruger afsenderen defineret i kampagnen, mens alle andre e-mails sendes fra no-reply@npstoday.com.`},
addNotificationTrigger: () => {return `Tilføj NPS Notifikation trigger`},
to: () => {return `til`},
userDetails: () => {return `Brugerdetaljer`},
personalInformation: () => {return `Personlig Information`},
name: () => {return `Navn`},
username: () => {return `Brugernavn`},
phoneNumber: () => {return `Telefonnummer`},
address: () => {return `Adresse`},
details: () => {return `Detaljer`},
emailConfirmed: () => {return `Email Bekræftet`},
created: () => {return `Oprettet`},
unknownDate: () => {return `Ukendt dato`},
userId: () => {return `Bruger ID`},
role: () => {return `Rolle`},
companyAdmin: () => {return `Virksomhedsadministrator`},
companyUser: () => {return `Virksomhedsbruger`},
superAdmin: () => {return `Superadministrator`},
management: () => {return `Administration`},
account: () => {return `Konto`},
users: () => {return `Brugere`},
licenses: () => {return `Licenser`},
user: () => {return `bruger`},
createOrLinkLicenseHelpMessage: () => {return `Ingen licens fundet for den nuværende organisation. Tildel en eksisterende licens til denne organisation fra listen nedenfor, eller klik på rediger-knappen øverst til højre for at begynde at oprette en ny licens.`},
assignLicenseHelpMessage: () => {return `Tildel en eksisterende licens til denne organisation fra listen nedenfor.`},
save: () => {return `Gem`},
licenseDisplayValue: (customer: string, vat: string) => {return `Kunde: ${customer} | VAT: ${vat}`},
noCustomerName: () => {return `Intet kundenavn angivet`},
customerName: () => {return `Kundenavn`},
vatNumber: () => {return `Momsnummer`},
countryCode: () => {return `Landekode`},
currency: () => {return `Valuta`},
seats: () => {return `Brugere`},
licenseDetails: () => {return `Licensoplysninger`},
licenseCreated: () => {return `Licens oprettet`},
licenseCreationError: () => {return `Fejl ved oprettelse af licens`},
licenseUpdated: () => {return `Licens opdateret`},
licenseUpdateError: () => {return `Fejl ved opdatering af licens`},
licenseAssignedToOrg: () => {return `Licensen er succesfuldt tildelt den nuværende organisation`},
licenseAssignmentError: () => {return `Fejl ved tildeling af licens`},
universalLinkTitle: () => {return `Universal Link-kampagne`},
universalLinkDescription: () => {return `Aktivér denne indstilling, hvis du ønsker at generere et link til denne kampagne, hvor kunderne kan bedømme uden at være registreret.`},
universalLinkLabel: () => {return `Opret universelt link`},
autoReloadTitle: () => {return `Automatisk genindlæsning`},
autoReloadDescription: () => {return `Aktivér denne indstilling for at genindlæse spørgeskemaet automatisk 2 sekunder efter det er fuldført. Denne funktion er ideel til opsætning af en spørgeskemastation på en tablet, hvor flere kunder løbende kan afgive deres feedback. Bemærk: Denne indstilling kan kun anvendes sammen med en Universal Link-kampagne.`},
autoReloadLabel: () => {return `Aktivér automatisk genindlæsning`},
reassignLicense: () => {return `Tildel en anden licens`},
cancel: () => {return `Annuller`},
productConfigurations: () => {return `Produktkonfigurationer`},
productConfigurationsDescription: () => {return `Administrer konfigurationer for dine nps.today-produkter`},
configuration: () => {return `Konfiguration`},
updateEmployeeAccess: () => {return `Opdater medarbejderadgang`},
employeeAccess: () => {return `Medarbejderadgang`},
hasAccess: () => {return `Har adgang`},
firstName: () => {return `Fornavn`},
lastName: () => {return `Efternavn`},
grantAccessToEmployees: () => {return `Giv adgang til følgende medarbejdere?`},
revokeAccessFromEmployees: () => {return `Fjern adgang fra følgende medarbejdere?`},
revokeAccessForAll: () => {return `Fjern adgang fra alle`},
grantAccessToAll: () => {return `Giv adgang til alle`},
revokeAccessForAllDescription: () => {return `Er du sikker på, at du vil fjerne adgangen for alle medarbejdere?`},
grantAccessToAllDescription: () => {return `Er du sikker på, at du vil give adgang til alle medarbejdere?`},
inactiveEmployeeAccess: () => {return `Følgende medarbejdere er inaktive og kan ikke få adgang. De skal markeres som aktive, for at de kan få adgang`},
inactiveEmployeeAccessDescription: () => {return `Dette vil kun påvirke aktive medarbejdere`},
universalLink: () => {return `Universal Link`},
qrCodeLinkDescription: () => {return `Når du scanner QR-koden, vil brugeren blive omdirigeret til dette link.`},
imageType: () => {return `Billedtype`},
qrCodeDamageResistenceLevel: () => {return `Skadesmodstandsniveau`},
qrCodeDamageResistenceLevelDescription: () => {return `Vælg hvor modstandsdygtig QR-koden skal være over for skader eller snavs. Højere niveauer kan håndtere mere skade, men gemmer færre data.`},
qrCodeDamageResistenceLevelDescriptionLow: () => {return `Lav: Håndterer mindre skade. Bedst til rene miljøer og høj datakapacitet.`},
qrCodeDamageResistenceLevelDescriptionMedium: () => {return `Mellem: Håndterer nogle skader. God balance til generel brug.`},
qrCodeDamageResistenceLevelDescriptionHigh: () => {return `Høj: Håndterer moderate skader. Velegnet til let hårde forhold.`},
qrCodeDamageResistenceLevelDescriptionVeryHigh: () => {return `Meget høj: Håndterer betydelige skader. Bedst til meget hårde forhold, men reducerer datakapacitet.`},
qrCodeDamageResistenceLevelLow: () => {return `Lav`},
qrCodeDamageResistenceLevelMedium: () => {return `Mellem`},
qrCodeDamageResistenceLevelHigh: () => {return `Høj`},
qrCodeDamageResistenceLevelVeryHigh: () => {return `Meget høj`},
backgroundColor: () => {return `Baggrundsfarve`},
foregroundColor: () => {return `Forgrundsfarve`},
margin: () => {return `Margin`},
qrCodeAddImageToCenterOfQRCode: () => {return `Tilføj billede til midten af QR-koden`},
clearBackgroundBegindImage: () => {return `Fjern baggrund bag billede`},
qrGeneratorDisclaimer: () => {return `Test altid din QR-kode manuelt, før du bruger den kommercielt. Test den i det miljø, den er beregnet til, for at sikre optimal ydeevne og pålidelighed. Kontroller læsbarhed under forskellige lysforhold, på forskellige enheder og fra forskellige vinkler.`},
close: () => {return `Luk`},
download: () => {return `Download`},
resetQrCode: () => {return `Nulstil QR-kode`},
qrCodeInvalidTitle: () => {return `Ugyldig QR-kode`},
qrCodeInvalidDescription: () => {return `Justér venligst indstillingerne og prøv igen.`},
qrCodeInvalidDetailDamageResistenceLevel: () => {return `Skadesmodstandsniveauet kan være for lavt.`},
qrCodeInvalidDetailSize: () => {return `QR-kodens størrelse kan være for lille.`},
qrCodeInvalidDetailColor: () => {return `Farverne kan være for ens. Forgrundsfarven skal være mørkere end baggrundsfarven.`},
qrCodeInvalidDetailImage: () => {return `Det indlejrede billede kan være for stort.`},
generateQrCode: () => {return `Generer QR-kode`},
employeeAccessDescription: () => {return `Konfigurer hvilke af dine medarbejdere der har adgang til Feedback app`},
invalidPhonuNumber: () => {return `Telefonnummer er ugyldigt`},
invalidEmail: () => {return `Email skal være en gyldig email adresse`},
company: () => {return `Virksomhed`},
andNMore: (count: string) => {return `...og ${count} mere`},
respondentSpreadSheetUploadSuccessDescription: () => {return `Din Excel-fil med respondentoplysninger er blevet succesfuldt uploadet og behandles nu. Dine surveys er ved at blive forberedt og vil blive sendt snart.`},
addRespondentsSpreadsheetDescription: (column: string, url: string, style: string) => {return `For at tilføje respondenter til kampagnen skal din fil indeholde mindst én kolonne med navnet '${column}'. Hent vores skabelon <a href=${url} download style=${style}>her</a> for at komme i gang.`},
timeOfDispatch: () => {return `Sendetidspunkt`},
uploadedRespondents: () => {return `Uploadede respondenter`},
total: () => {return `Total`},
invalidRespondents: (count: string) => t("invalidRespondents", {count: count}),
preview: () => {return `Forhåndsvisning`},
sendOutSurvey: () => {return `Send survey ud`},
campaigns: () => {return `Kampagner`},
campaign: () => {return `Kampagne`},
campaignInformation: () => {return `Kampagneoplysninger`},
campaignName: () => {return `Kampagnenavn`},
nameOfSmsSender: () => {return `Navn på SMS-afsender`},
smsText: () => {return `SMS tekst`},
subjectOnEmail: () => {return `Emne i e-mail`},
senderOnEmail: () => {return `Afsender på e-mail`},
surveySettings: () => {return `Survey indstillinger`},
uploadRespondents: () => {return `Upload respondenter`},
newRespondent: () => {return `Ny respondent`},
sendOutSurveyType: (type: string) => t("sendOutSurveyType", {type: type}),
nameOfEmailSender: () => {return `Navn på e-mail afsender`},
campaignSettingsNavToCampaignEdit: (url: string, style: string) => {return `For at ændre nogen af ​​de ovenstående kampagneindstillinger, bedes du gå til <a href=${url} style=${style}>Kampagne Editoren</a>.`},
blockSendOuts: () => {return `Bloker udsendelser`},
blockSendOutsDescription: () => {return `Denne kampagne er indstillet til at blokere udsendelser. Ingen automatiske udsendelser vil forekomme.`},
duplicatesTitle: () => {return `Dubletter`},
duplicatesDescription: () => {return `Denne kampagne tillader dubletter. Respondenter kan modtage flere surveys.`},
throttlingTitle: () => {return `Throttling`},
throttlingDescription: () => {return `Ingen grænser for antallet af udsendte surveys i en given periode.`},
forceAnonymousTitle: () => {return `Gennemtving anonymitet`},
forceAnonymousDescription: () => {return `Alle svar er anonyme. Svar kan ikke knyttes til respondenter.`},
singleSubmitTitle: () => {return `Enkelt indsendelse`},
singleSubmitDescription: () => {return `Kun endelige indsendelser gemmes. Forhindrer automatiske svar, men kan påvirke svarprocenten.`},
campaignSettingsHeader: () => {return `Kampagneindstillinger`},
emailRatingVisibilityHeading: () => {return `Synlighed af e-mailvurdering`},
emailRatingVisibilityBody: () => {return `Kontroller synligheden af NPS spørgsmål og vurderingsknapper i dine e-mails. Når deaktiveret, vil survey elementer som hovedspørgsmål og 0-10 bokse være skjult. Sørg for at inkludere survey-URL'en i 'Introduktionsteksten' ved hjælp af {REPLY_URL} for at brugerne kan få adgang til surveyet fra e-mailen.`},
emailRatingVisibilityLabel: () => {return `Aktiver NPS spørgsmål og vurderingsknapper i e-mails`},
emailRatingVisibilityFurtherInfo: () => {return `Denne indstilling er til scenarier, hvor begrænsning af antallet af links i e-mailen vil påvirke leveringsraten.`},
blockSendOutsBody: () => {return `Denne funktion vil blokere alle udsendelser. Respondenter og data kan stadig tilføjes til kampagnen, hvilket giver dig mulighed for at bruge denne funktion i et testscenarie.`},
blockSendOutsLabel: () => {return `Bloker kampagneudsendelser`},
sendoutDelayHeading: () => {return `Forsendelsesforsinkelse`},
sendoutDelayBody: () => {return `Angiv hvor lang tid der skal gå fra et survey er markeret som klar til at blive sendt, til den faktisk sendes til respondenten. Dette kan være nyttigt, når hændelsen, der udløser et survey, sker før en kundetransaktion er afsluttet. For eksempel, en kunde i en fysisk butik foretager et køb, der udløser et survey, men du vil kun have, at kunden modtager surveyet, efter de har forladt butikken.`},
enableThrottlingHeading: () => {return `Aktivér throttling`},
enableThrottlingBody: () => {return `Når throttling aktiveres, sikrer systemet, at surveyet kun sendes til en respondent, hvis de ikke har modtaget noget survey fra denne eller andre kampagner inden for samme throttling gruppe i løbet af det angivne antal dage. Som standard er alle kampagner en del af en fælles throttling gruppe. Hvis der ikke vælges nogen throttling gruppe, vil kampagnen blive betragtet som uafhængig og kun påvirket af sine egne throttle-indstillinger. Det er vigtigt at bemærke, at throttling er gensidig og kun påvirker kampagner inden for samme gruppe. Dette betyder, at kampagner i separate throttling grupper ikke vil tage hensyn til hinandens sendte surveys, når de anvender deres respektive throttling regler.`},
duplicatesHeading: () => {return `Dubletter`},
duplicatesBody: () => {return `Tillad, at respondenter kan tilføjes til en kampagne flere gange. Dette er nyttigt for tilbagevendende eller langvarige kampagner.`},
duplicatesLabel: () => {return `Tillad dubletter`},
qrGeneratorDialogTitlte: () => {return `QR-kode generator`},
sizeInPixels: () => {return `Størrelse i pixels`},
imageHeightInPixels: () => {return `Billedhøjde i pixels`},
imageWidthInPixels: () => {return `Billedbredde i pixels`},
emailSubjectRequired: () => {return `E-mail emne er påkrævet`},
introTextIncludesReplyUrl: () => {return `Introduktionstekst skal indeholde {REPLY_URL}\ ved deaktivering af NPS spørgsmål og vurderingsknapper.`},
questionMinLength: () => {return `Spørgsmålet skal være mindst 5 tegn`},
questionRequired: () => {return `Spørgsmålet er påkrævet`},
smsLinkRequired: () => {return `Tilføj venligst {LINK} til SMS-teksten, samt et mellemrum før og efter linket.`},
smsSenderRequired: () => {return `SMS afsender er påkrævet`},
validEmail: () => {return `Indtast venligst en gyldig e-mailadresse`},
validWebhookUrl: () => {return `Indtast venligst en gyldig webhook URL`},
recipientsOrWebHookUrlRequired: () => {return `Indtast venligst en modtager værdi`},
throttlingDaysRequired: () => {return `Throttling dage er påkrævet, når throttling er aktiveret`},
mustBeValidUrl: () => {return `Skal være en gyldig URL`},
externalWebHookUrlRequired: () => {return `Ekstern webhook-URL er påkrævet for ekstern relætype.`},
authenticationTypeRequired: () => {return `Godkendelsestype er påkrævet for ekstern relætype.`},
usernameRequired: () => {return `Brugernavn er påkrævet for basic godkendelse.`},
passwordRequired: () => {return `Adgangskode er påkrævet for basic godkendelse.`},
apiKeyRequired: () => {return `API-nøgle er påkrævet.`},
clientIdRequired: () => {return `Klient-ID er påkrævet for OAuth.`},
clientSecretRequired: () => {return `Klientsecret er påkrævet for OAuth.`},
tokenEndpointRequired: () => {return `Token Endpoint-URL er påkrævet for OAuth.`},
authenticationSourceRequired: () => {return `Godkendelseskilde er påkrævet for OAuth.`},
relaySwitchLabel: () => {return `Relæ`},
relayDescription: () => {return `Aktivering af Relæ tillader direkte kontrol over requesthåndtering ved at omdirigere dem til din angivne webhook. Når aktiv, vil vores system ikke længere behandle disse requests; i stedet vil de blive sendt direkte til dit angivne endpoint for manuel styring. Dette tilbyder øget fleksibilitet, hvilket muliggør skræddersyet behandling i overensstemmelse med dine systems specifikke krav.`},
type: () => {return `Type`},
authentication: () => {return `Godkendelse`},
none: () => {return `Ingen`},
basic: () => {return `Basic`},
apiKey: () => {return `API-nøgle`},
oAuth: () => {return `OAuth`},
password: () => {return `Adgangskode`},
clientId: () => {return `Klient-ID`},
clientSecret: () => {return `Klientsecret`},
tokenEndpointUrl: () => {return `Token Endpoint-URL`},
authorizationSource: () => {return `Godkendelseskilde`},
amountOfDaysRequired: () => {return `Antal dage er påkrævet`},
automaticAnonymization: () => {return `Automatisk anonymisering`},
automaticAnonymizationDescription: () => {return `Beskyt dine respondenters privatliv ved at aktivere denne funktion, der er designet til automatisk at anonymisere deres data efter et specificeret antal dages inaktivitet. Inaktivitet i denne sammenhæng refererer til en periode, hvor en respondent ikke har deltaget i nogen surveys. Ved at indstille den ønskede opbevaringsperiode sikrer du overholdelse af databeskyttelsesregler, mens risikoen for uautoriseret adgang eller misbrug af personlige oplysninger reduceres. De anonymiserede data, selvom de er frataget personligt identificerbare oplysninger, opbevares til statistiske formål.`},
anonymizeDataAfter: () => {return `Anonymiser data efter:`},
years: () => {return `År`},
days: () => {return `Dage`},
humanVerification: () => {return `Menneskelig Verifikation`},
humanVerificationDescription1: () => {return `Aktivering af Menneskelig Verifikation integrerer Turnstile, en sikkerhedsfunktion svarende til reCAPTCHA, direkte på din surveyside. Denne mekanisme hjælper med at sikre, at svarene kommer fra rigtige mennesker, ikke bots. Når denne indstilling er aktiv, kører en usynlig kontrol i baggrunden. Hvis systemet ikke automatisk kan verificere respondenten, vil de blive bedt om at markere en afkrydsningsfelt.`},
humanVerificationDescription2: () => {return `Denne funktion er fordelagtig, da den betydeligt reducerer risikoen for automatiserede botsvar, hvilket øger nøjagtigheden og pålideligheden af dine surveydata. Ved at filtrere potentielle botsvar sikrer du, at de indsigter, der trækkes fra dine surveys, er baseret på ægte menneskelig feedback.`},
turnstileOverview: (url: string, style: string) => {return `For mere information om Turnstile og dets fordele, se venligst denne <a href=${url} target='__blank' style=${style}>Cloudflare Turnstile Oversigt</a>.`},
addToPuzzelPGap: () => {return `Tilføj til Puzzel P-Gap`},
puzzelPGapDescription: () => {return `Denne funktion fungerer kun i Puzzel og beder agenter om at besvare dette spørgsmål under en aktiv P-Gap-periode.`},
phoneWithoutCountryCode: (initialValue: string) => {return `Det aktuelt gemte telefonnummer er ${initialValue} uden en landekode. Vælg den relevante landekode.`},
useFormattedNumber: (formattedValue: string) => {return `Brug ${formattedValue}`},
lessThanThrottling: () => {return `Maks antal dage må ikke overstige throttling dage`},
forwardedCampaignRequired: () => {return `Kampagne er påkrævet`},
forwardedLabel: () => {return `Send throttled surveys til en anden kampagne`},
forwardedFurtherInfo: () => {return `Aktivering af denne indstilling sikrer, at surveys, der er underlagt throttling, videresendes til en anden kampagne i stedet for at blive tilbageholdt.`},
forwardedDaysLabel: () => {return `Maks dage`},
forwardedDaysFurtherInfo: (forwardedDaysLabelStyle: string, forwardedDaysLabel: string) => {return `Surveys vil blive omdirigeret til den angivne kampagne, hvis de angivne <span style=${forwardedDaysLabelStyle}>${forwardedDaysLabel}</span> ikke er gået siden den sidste undersøgelse blev sendt.`},
campaignWithNoName: (campaignId: string) => {return `Kampagne uden navn - id ${campaignId}`},
defineForwardedDays: () => {return `Definér maks dage`},
productOrderAmountUsedInfo: (amountUsed: string, integrationName: string, totalAmount: string) => {return `${amountUsed} medarbejdere har adgang til ${integrationName} ud af i alt ${totalAmount} tilladte.`},
fullExpandedReport: () => {return `Fuld udvidet rapport`},
fullExpandedReportDescription: () => {return `Adskiller hvert custom felt og alle felter fra Advanced Survey Module i separate kolonner for detaljeret analyse. Denne udvidede visning sikrer nemmere dataoverblik og manipulation, hvilket giver dybdegående indsigt i surveysvar. Denne rapport har en grænse på 250.000 rækker inkluderet i rapporten.`},
fullExpandedReportWithAsm: () => {return `Fuldt udvidet rapport - Med ASM-spørgsmål som overskrift`},
fullExpandedReportWithAsmDescription: () => {return `En udvidelse af den fuldt udvidede rapport, denne version inkluderer undersøgelsesspørgsmål som overskrifter i kolonnerne i det avancerede undersøgelsesmodul, hvilket giver klarere kontekst og lettere analyse. Denne rapport har en grænse på 250.000 rækker inkluderet i rapporten.`},
reportEditedResponsesAndRatingsByEmployees: () => {return `Redigerede svar og vurderinger af medarbejdere`},
fullReport: () => {return `Fuld rapport (anbefalet)`},
fullReportDescription: () => {return `Denne rapport har en grænse på 250.000 rækker inkluderet i rapporten.`},
npsByCampaign: () => {return `NPS efter kampagne`},
npsByCategory: () => {return `NPS efter kategori`},
npsBySegment: () => {return `NPS efter segment`},
npsByIndustry: () => {return `NPS efter branche`},
npsByEmployee: () => {return `NPS efter medarbejder`},
unansweredOnlyData: () => {return `Ubesvarede (kun data)`},
responsesAndEmployeesOnlyData: () => {return `Svar og medarbejdere (kun data)`},
responsesAndRespondentsOnlyData: () => {return `Svar og respondenter (kun data)`},
selectAtLeastOneCampaign: () => {return `Vælg mindst én kampagne for at inkludere i rapportopsætningen`},
secret: () => {return `Secret`},
reservedFieldMessage: (field: string) => {return `"${field}" er et reserveret felt`},
phone: () => {return `Telefon`},
externalId: () => {return `Eksternt ID`},
responsibleEmployeeEmail: () => {return `Ansvarlig medarbejders e-mail`},
field: () => {return `Felt`},
value: () => {return `Værdi`},
uniquePersonalizedLink: () => {return `Unikt personligt link`},
generateLink: () => {return `Generér link`},
respondentMergeInfo: (url: string, style: string) => {return `Har du brug for mere hjælp? Lær hvordan vi identificerer respondenter&nbsp;<a href=${url} target="__blank" style=${style}>her</a>.`},
smsTextLengthCalculation: (dynamicContent: string) => {return `Længden af denne SMS-besked kan ikke beregnes præcist, da følgende indhold er dynamisk: ${dynamicContent}`},
smsLinkInsertDescription: () => {return `Når SMS-linket indsættes i surveyteksten, kræves der to tegn: et mellemrum før og efter linket. Dette sikrer, at linket er korrekt formateret og funktionelt.`},
usedSegments: (numberOfSegments: string) => t("usedSegments", {numberOfSegments: numberOfSegments}),
estimated: () => {return `<span>Estimeret&nbsp;</span>`},
ratingChange: () => {return `Vurderingsændring`},
ratingChangeDescription: () => {return `Tillad respondenter at ændre deres NPS-vurdering, før de klikker på send-knappen.`},
ratingChangeLabel: () => {return `Tillad vurderingsændring`},
reminders: () => {return `Påmindelser`},
remindersDescription: () => {return `Hvis en respondent ikke svarer på en kampagne, har du mulighed for at sende en påmindelse. Påmindelser sendes ud efter et valgt antal dage, men kan også deaktiveres.`},
sendAutomaticReminder: () => {return `Send automatisk påmindelse`},
hours: () => {return `Timer`},
unsubscribe: () => {return `Afmeld`},
unsubscribeDescription: () => {return `Tillad respondenter at afmelde fremtidige kampagner. Et afmeldelseslink tilføjes i bunden af e-mailen.`},
unsubscribeLabel: () => {return `Tillad afmelding`},
unsubscribeText: () => {return `Afmeldingstekst`},
unsubscribeTextPlaceholder: () => {return `Skriv en besked, som respondenten vil se efter afmelding.`},
smsDelivery: () => {return `SMS-udsendelse`},
smsDeliveryDescription: () => {return `Aktivér denne indstilling, hvis du ønsker at kunne sende kampagnen via SMS. nps.today sender ikke e-mails, hvis denne indstilling er aktiveret.`},
smsDeliveryLabel: () => {return `Send survey som SMS`},
smsSender: () => {return `SMS-afsender`},
smsTextPlaceholder: () => {return `Skriv teksten, der skal sendes som SMS.`},
consentCollection: () => {return `Indsamling af samtykke`},
consentCollectionDescription: () => {return `Tilføjer en ekstra afkrydsningsboks til at indsamle samtykke. Vi anbefaler, at du tilføjer dette til din egen privatlivspolitik.`},
consentCollectionLabel: () => {return `Aktivér samtykkeindsamling`},
optIn: () => {return `Opt-in`},
optOut: () => {return `Opt-out`},
optOutDescription: (style: string) => {return `Selvom GDPR ikke specifikt forbyder opt-out samtykke, siger <a target="_blank" rel="noopener" style=${style} href='https://ico.org.uk/media/about-the-ico/consultations/2013551/draft-gdpr-consent-guidance-for-consultation-201703.pdf'>ICO (Information Commissioner's Office)</a>, at opt-out muligheder "stor set er det samme som forudafkrydsede bokse, som er forbudt." Overvej om denne indstilling er passende for din situation.`},
consentText: () => {return `Samtykketekst`},
consentTextPlaceholder: () => {return `Jeg accepterer, at mit svar må bruges til offentlig visning.`},
afterFollowUpQuestion: () => {return `Efter opfølgningspørgsmål`},
atTheEndOfTheSurvey: () => {return `Ved afslutningen af surveyen`},
forceAnonymousExplanation: () => {return `Tvinger alle svar til at være anonyme. Respondenter vil stadig eksistere, men et svar gives til en respondent, som ikke kan linkes til et svar eller deltagelse.`},
singleSubmitExplanantion: () => {return `Tvinger undersøgelsen til kun at sende svaret, hvis respondenten klikker på den sidste "send"-knap i undersøgelsen. Alle optegnede data går tabt, hvis den sidste send-knap ikke klikkes. Denne indstilling kan bruges til at forhindre automatiserede svar fra spamfiltre og sikkerhedssystemer. Kun anbefalet til B2B-scenarier.`},
singleSubmitWarning: () => {return `Bemærk, at du kan få lavere svarprocenter, når denne indstilling er aktiveret.`},
industry: () => {return `Industri`},
companies: () => {return `Virksomheder`},
deleteCompanies: (companies: string) => t("deleteCompanies", {companies: companies}),
uploadCompanies: () => {return `Upload virksomheder`},
delete: () => {return `Slet`},
deleteCompaniesDescription: (companies: string) => t("deleteCompaniesDescription", {companies: companies}),
makeOrganizationsInactive: () => {return `Gør de følgende organisationer inaktive?`},
makeOrganizationsActive: () => {return `Gør de følgende organisationer aktive?`},
deleteUserTitle: () => {return `Du er ved at slette en bruger`},
deleteUsersDescription: (users: string) => t("deleteUsersDescription", {users: users}),
addNewUser: () => {return `Tilføj ny bruger`},
selectRole: () => {return `Vælg rolle`},
addUser: () => {return `Tilføj bruger`},
anonymizeEmployees: () => {return `Anonymiser medarbejdere`},
anonymizeCompanies: () => {return `Anonymiser virksomheder`},
anonymizeUsers: () => {return `Anonymiser brugere`},
anonymizationExplanation: () => {return `Medarbejdere, virksomheder og brugere vil kun blive anonymiseret, hvis de er inaktive`},
changeAnonymizationConfigurationDescription: (oldYears: string, oldDays: string, oldCombinedDays: string, newYears: string, newDays: string, newCombinedDays: string) => t("changeAnonymizationConfigurationDescription", {oldYears: oldYears, oldDays: oldDays, oldCombinedDays: oldCombinedDays, newYears: newYears, newDays: newDays, newCombinedDays: newCombinedDays}),
disableAutomaticAnonymizationDescription: (oldYears: string, oldDays: string, oldCombinedDays: string) => t("disableAutomaticAnonymizationDescription", {oldYears: oldYears, oldDays: oldDays, oldCombinedDays: oldCombinedDays}),
employeeAnonymizationEnabled: () => {return `Medarbejderanonymisering er blevet aktiveret. Dette vil påvirke alle inaktive medarbejdere, der endnu ikke er anonymiseret.`},
companyAnonymizationEnabled: () => {return `Virksomhedsanonymisering er blevet aktiveret. Dette vil påvirke alle inaktive virksomheder, der endnu ikke er anonymiseret.`},
userAnonymizationEnabled: () => {return `Brugeranonymisering er blevet aktiveret. Dette vil påvirke alle inaktive brugere, der endnu ikke er anonymiseret.`},
disableAutomaticAnonymization: () => {return `Deaktiver automatisk anonymisering`},
changeAutomaticAnonymization: () => {return `Opdater automatisk anonymisering`},
continue: () => {return `Fortsæt`},
logo: () => {return `Logo`},
displayName: () => {return `Visningsnavn`},
updateOrganizations: () => {return `Opdater organisationer`},
ensureValidLicenseDescription: () => {return `Denne mulighed kræver, at den aktuelle organisation har en aktiv licens. Sørg for, at der er tilknyttet en gyldig licens til denne organisation for at fortsætte.`},
addLinkedOrganization: () => {return `Tilføj linket organisation`},
addOrganization: () => {return `Tilføj organisation`},
onlyAccessibleForSuperAdminsTitle: () => {return `Denne side er kun tilgængelig for <b>Super Admins</b>`},
onlyAccessibleForSuperAdminsDescription: () => {return `Her finder du funktioner, der er begrænset til Super Admins og muligvis ikke direkte relaterer til den aktuelle organisation du er logget ind på`},
campaignIsActive: () => {return `Denne kampagne er aktiv`},
campaignIsClosed: () => {return `Denne kampagne er lukket`},
nps: () => {return `NPS`},
respondents: () => {return `Respondenter`},
responses: () => {return `Svar`},
responsePercentage: () => {return `Svar %`},
reminderDelay: () => {return `Påmindelsesforsinkelse`},
throttlingGroup: () => {return `Throttlinggruppe`},
allowDuplicates: () => {return `Tillad dubletter`},
question: () => {return `Spørgsmål`},
linkCampaign: () => {return `Link kampagne`},
emailCampaign: () => {return `E-mail kampagne`},
smsCampaign: () => {return `Sms kampagne`},
edit: () => {return `Rediger`},
editCompany: () => {return `Rediger virksomhed`},
accountNumber: () => {return `Kontonummer`},
segment: () => {return `Segment`},
address1: () => {return `Adresse 1`},
address2: () => {return `Adresse 2`},
zipCode: () => {return `Postnummer`},
country: () => {return `Land`},
team: () => {return `Team`},
title: () => {return `Titel`},
department: () => {return `Afdeling`},
division: () => {return `Division`},
employees: () => {return `Medarbejdere`},
uploadEmployees: () => {return `Upload medarbejdere`},
addEmployee: () => {return `Tilføj medarbejder`},
deleteEmployeesTitle: (count: string) => t("deleteEmployeesTitle", {count: count}),
deleteEmployeesDescription: (count: string) => t("deleteEmployeesDescription", {count: count}),
deleteXEmployees: (count: string) => t("deleteXEmployees", {count: count}),
processingHasCompleted: () => {return `Behandlingen er fuldført`},
respondentsAreBeingProcessed: () => {return `Respondenter behandles i øjeblikket for denne kampagne`},
percentageSucceeded: (percentage: string) => {return `${percentage}% lykkedes`},
percentageFailed: (percentage: string) => {return `${percentage}% mislykkedes`},
ratioSucceeded: (succeeded: string, total: string) => {return `${succeeded} / ${total} lykkedes`},
ratioFailed: (failed: string, total: string) => {return `${failed} / ${total} mislykkedes`},
failedRespondents: () => {return `Mislykkede respondenter`},
copyToClipboard: () => {return `Kopier til udklipsholder`},
copiedTextToClipboard: () => {return `Tekst kopieret til udklipsholder`},
dismiss: () => {return `Luk`},
percentageNotSent: (percentage: string) => {return `${percentage}% ikke sendt`},
ratioNotSent: (succeeded: string, total: string) => {return `${succeeded} / ${total} ikke sendt`},
notSentRespondentsDescription: (url: string, style: string) => {return `Disse respondenter blev tilføjet til systemet, men ingen surveys vil blive sendt til dem. Du kan tjekke årsagerne til dette nedenfor og læse mere om, hvad statuskoderne betyder&nbsp;<a href=${url} target='__blank' style=${style}>her</a>&nbsp;`},
failedRespondentsDescription: () => {return `Disse respondenter fejlede under behandlingen. Det betyder, at de ikke blev tilføjet til systemet, og der vil ikke blive sendt nogen surveys til dem. Tjek detaljerne nedenfor for at se, hvad der gik galt`},
respondentNotSent: () => {return `Respondenter som ikke fik tilsendt surveys`},
settings: () => {return `Indstillinger`},
feedbackAppSettingsDescription: () => {return `Konfigurer, hvor meget information der skal vises i Feedback App'en`},
respondentInformation: () => {return `Respondentinformation`},
names: () => {return `Navne`},
comments: () => {return `Kommentarer`},
categories: () => {return `Kategorier`},
ratings: () => {return `Vurderinger`},
titles: () => {return `Titler`},
phoneNumbers: () => {return `Telefonnumre`},
companyNames: () => {return `Virksomhedsnavne`},
externalIds: () => {return `Eksterne id'er`},
emails: () => {return `E-mails`},
answerN: (number: string) => {return `Svar ${number}`},
answer1To5Settings: () => {return `Svar 1-5 indstillinger`},
customText: () => {return `Custom tekst`},
updateSettings: () => {return `Opdater indstillinger`},
feedbackApp: () => {return `Feedback App`},
extraInformation: () => {return `Ekstra information`},
reviews: () => {return `Reviews`},
updateConfiguration: () => {return `Opdater konfiguration`},
enablePublicReviews: () => {return `Aktiver offentlige anmeldelser`},
reviewName: () => {return `Review navn`},
reviewDescription: () => {return `Review beskrivelse`},
reviewNameHelperText: () => {return `Konfigurer navnet, som vil være søgbart på review.nps.today. Vær opmærksom på, at ændring af denne værdi også vil ændre URL'en, hvor din organisations review side er placeret.`},
reviewDescriptionHelperText: () => {return `Konfigurer beskrivelsen, der vil blive vist i 'Om' sektionen på review siden for din organisation`},
reviewNameError: (characters: string) => {return `Dit reviews navn indeholder reserverede tegn. Fjern venligst følgende tegn: ${characters}.`},
addTemplate: () => {return `Tilføj skabelon`},
addCampaign: () => {return `Tilføj kampagne`},
deleteTemplates: (count: string) => t("deleteTemplates", {count: count}),
deleteTemplatesDescription: () => {return `Er du sikker på, at du vil slette de valgte skabeloner?`},
uploadNewLogo: () => {return `Upload nyt logo`},
addThrottlingGroup: () => {return `Tilføj throttlinggruppe`},
addNpsNotification: () => {return `Tilføj NPS notifikation`},
authenticationConfiguration: () => {return `Authentication konfiguration`},
disableFormLogin: () => {return `Deaktiver login med e-mail og adgangskode`},
disableFormLoginDescription: () => {return `Forhindrer brugere i at logge ind med e-mail og adgangskode. Når denne indstilling er aktiveret, skal alle brugere i organisationen bruge Single Sign-On (SSO) for at få adgang til systemet.`},
dalux: () => {return `Dalux`},
sendoutTime: () => {return `Udsendelsestidspunkt`},
sendoutTimeDescription: () => {return `Angiv tidspunktet på dagen (UTC), hvor surveyen skal sendes ud`},
daluxApiKeyDescription: () => {return `Indtast en API-nøgle, der kan bruges til at interagere med Dalux API'et`},
daluxCampaignSelectDescription: () => {return `Vælg den kampagne, der skal bruges til Dalux-integration`},
campaignRequired: () => {return `Kampagne er påkrævet`},
sendoutTimeRequired: () => {return `Udsendelsestidspunkt er påkrævet`},
integrationNotEnabled: (integration: string) => {return `${integration} er ikke aktiveret for din organisation. Der skal laves en anmodning for at aktivere det.`},
requestIntegrationAccessSubject: (integration: string) => {return `${integration} adgang`},
productCategory: () => {return `Produktkategori`},
logoUrl: () => {return `Logo-URL`},
removeIntegration: () => {return `Fjern integration`},
addIntegration: () => {return `Tilføj integration`},
integrationStore: () => {return `Integrationsbutik`},
language: () => {return `Sprog`},
danish: () => {return `Dansk`},
english: () => {return `Engelsk`},
ratedBy: (name: string) => {return `Vurderet af ${name}`},
unrated: () => {return `Ikke vurderet`},
npsScore: () => {return `NPS-score`},
followUpComment: () => {return `Opfølgende kommentar`},
category: () => {return `Kategori`},
agentRating: () => {return `Agentvurdering`},
pgap: () => {return `P-Gap`},
respondentRating: () => {return `Respondentvurdering`},
choiceHasBeenDeselected: (choice: string) => {return `${choice} er blevet fravalgt`},
choiceHasBeenDeselectedDescription: (choice: string) => {return `Muligheden ${choice} er ikke længere tilgængelig i denne kampagne. Hvis du gemmer dine ændringer, vil du ikke kunne tilføje denne mulighed igen.`},
respondentWasRemovedFromList: () => {return `Respondenten blev fjernet fra listen`},
rating: () => {return `Vurdering`},
comment: () => {return `Kommentar`},
ratingDate: () => {return `Vurderingsdato`},
commentDate: () => {return `Kommentardato`},
reminderScheduledDate: () => {return `Planlagt påmindelsesdato`},
reminderSendDate: () => {return `Afsendelsesdato for påmindelse`},
surveyScheduledDate: () => {return `Planlagt survey dato`},
surveySendDate: () => {return `Afsendelsesdato for survey`},
responsible: () => {return `Ansvarlig`},
status: () => {return `Status`},
settingIsRequiredWhenPgapIsShown: () => {return `Denne indstilling er påkrævet, når P-Gap vises.`},
ratingInformation: () => {return `Vurderingsinformation`},
showPgapDescription: () => {return `Visning af P-Gap kræver, at både vurderinger og agentvurderinger også vises. Hvis du aktiverer denne indstilling, vil disse indstillinger også blive aktiveret.`},
agentRatings: () => {return `Agentvurderinger`},
}
}